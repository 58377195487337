<template>
  <div>
    <dashboard-payments
      v-if="G_PROGRAMS_IN_NEW_PAYMENT.includes(programId)"
      :client-account-id="$route.params.idClient"
      :key="keyPaymentSchedule"
    ></dashboard-payments>

    <div v-else>
      <b-row class="justify-content-around">
        <b-col cols="4">
          <b-row class="mb-1">
            <b-table
              :items="charges"
              sticky-header="50vh"
              no-border-collapse
              responsive
              :fields="fieldsCharges"
              ref="listCharges"
            >
              <template #cell(created_at)="{ item }">
                {{ item.created_at | myGlobal }}
              </template>
              <template #cell(amount)="{ item }">
                $ {{ item.amount }}
              </template>
            </b-table>
          </b-row>
          <b-row>
            <b-button
              :disabled="isResponsibleAccount"
              variant="primary"
              @click="openAddChargeModal"
              ><feather-icon icon="PlusIcon" /> Add Charge</b-button
            >
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-row class="mb-1">
            <b-table
              :items="payments"
              :fields="fieldsPayments"
              sticky-header="50vh"
              no-border-collapse
              responsive
              ref="listPayments"
            >
              <template #cell(settlement_date)="{ item }">
                {{ item.settlement_date | myGlobal }}
                <!-- <feather-icon
                v-if="
                  item.update_payment_date === 0 &&
                  item.edit === 1 &&
                  item.amount !== 0
                "
                size="18"
                class="cursor-pointer"
                icon="Edit3Icon"
                @click="openUpdatePaymentModal(item.id, item.t_id)"
              />
              <feather-icon
                v-if="item.update_payment_date === 1 && item.amount !== 0"
                v-b-tooltip.hover.bottom
                icon="EyeIcon"
                class="cursor-pointer"
                size="18"
                :title="
                  'Updated by: ' +
                  item.user_updater +
                  ' - Updated at: ' +
                  item.updated_at
                "
              /> -->
                <feather-icon
                  v-if="
                    item.status_transaction_id != 1 &&
                    item.amount == 0 &&
                    item.type_transaction_id != 10 &&
                    item.type_transaction_id != 11
                  "
                  class="text-danger cursor-pointer"
                  size="18"
                  icon="XCircleIcon"
                  @click="deleteWithouPayment(data.item.t_id)"
                />
              </template>
              <template #cell(amount)="{ item }">
                $ {{ item.amount }}
              </template>

              <template #cell(status)="data">
                <div :style="`color: ${data.item.color}; font-weight: bold`">
                  <feather-icon
                    @click="ApproveOrDeclineHeldTransaction(data.item)"
                    v-if="data.item.status_id == 4 || data.item.status_id == 19"
                    class="cursor-pointer text-warning mr-1"
                    icon="RefreshCcwIcon"
                    size="15"
                  />
                  <span v-if="data.item.status_id == 19" class="text-warning">
                    Held for review</span
                  >
                  <span v-else> {{ data.item.status }}</span>
                </div>
              </template>
            </b-table>
          </b-row>
          <b-row class="justify-content-end">
            <b-button
              :disabled="isResponsibleAccount"
              variant="primary"
              class="ml-1"
              @click="openCreditCardModal"
            >
              <feather-icon icon="PlusIcon" /> Credit Cards
            </b-button>
            <b-button
              :disabled="balanceAccount >= 0 || isResponsibleAccount"
              variant="primary"
              class="ml-1"
              @click="openMonthlyPaymentModal"
            >
              <feather-icon icon="PlusIcon" /> Monthly Payment
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <total-payments
        :feeAndCharge="totalCharges"
        :payments="totalPayments"
        :balance="balanceAccount"
      />
    </div>
    <add-charge-modal
      v-if="isActiveAddChargeModal"
      :title-add-charge="'ADD CHARGE'"
      @refresh="refreshCharges"
      @close="closeAddChargeModal"
    />
    <add-charge-modal-charges
      v-if="isActiveAddChargeModalCharges"
      :title-add-charge="'ADD CHARGE'"
      @refresh="refresh"
      @close="closeAddChargeModal"
    />
    <credit-card-modal
      v-if="isActiveCreditCardModal"
      :client="clientObj"
      @close="closeCreditCardModal"
    />
    <zero-payment-modal
      v-if="isActiveZeroPaymentModal"
      :zero-payment-transaction-id="zeroPaymentTransactionId"
      @refresh="refresh"
      @close="closeZeroPaymentModal"
    />
    <monthly-payment-modal
      v-if="isActiveMonthlyPaymentModal"
      @refreshTable="refresh"
      @close="closeMonthlyPaymentModal"
      @refreshPaymentScheduleGrid="refreshPaymentSchedule"
    />

    <update-payment-modal
      v-if="updatePaymentModalOn"
      :transaction-id="transactionId"
      :client="clientObj"
      @refresh="refresh"
      @close="closeUpdatePaymentModal"
    />
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      :from-bc-pay="true"
      @approveSup="updatePaymentModalOn = true"
      @closeModalApprove="modalApproveSupervisorOn = false"
    />
  </div>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
//components
import TotalPayments from "../utils/TotalsPayments.vue";
//modals
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import AddChargeModalCharges from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModalCharges.vue";
import CreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/CreditCardModal.vue";
import ZeroPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/ZeroPaymentModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import UpdatePaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/UpdatePaymentModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";

export default {
  components: {
    TotalPayments,
    AddChargeModal,
    AddChargeModalCharges,
    CreditCardModal,
    ZeroPaymentModal,
    MonthlyPaymentModal,
    UpdatePaymentModal,
    DashboardPayments,
  },
  data() {
    return {
      isActiveAddChargeModal: false,
      isActiveAddChargeModalCharges: false,
      isActiveCreditCardModal: false,
      isActiveZeroPaymentModal: false,
      isActiveMonthlyPaymentModal: false,
      fieldsCharges: [
        { key: "created_at", label: "Date" },
        { key: "fee", label: "Fee or Charge" },
        { key: "amount", label: "Amount" },
      ],
      fieldsPayments: [
        { key: "settlement_date", label: "Date" },
        { key: "type", label: "Type" },
        { key: "transaction_id", label: "Transaction ID" },
        { key: "amount", label: "Amount" },
        { key: "status", label: "Status" },
        { key: "method_payment", label: "Method" },
        { key: "status", label: "Status" },
      ],
      modalApproveSupervisorOn: false,
      updatePaymentModalOn: false,
      keyPaymentSchedule: Math.random(),
    };
  },
  methods: {
    async ApproveOrDeclineHeldTransaction(transaction, isAutoApprove = false) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        if (!isAutoApprove) {
          this.refresh();
          this.showGenericToast({
            position: "top-right",
            variant: data.variant,
            icon: data.icon,
            title: data.message,
            text: data.text,
          });
        }
        // else will update automatic by socket src/views/commons/socket/check-transaction-status.socket.js
      } catch (error) {
        this.showErrorSwal(error.data.response.message);
      } finally {
        this.removePreloader();
      }
    },

    openMonthlyPaymentModal() {
      this.isActiveMonthlyPaymentModal = true;
    },
    closeMonthlyPaymentModal() {
      this.isActiveMonthlyPaymentModal = false;
    },
    openCreditCardModal() {
      this.isActiveCreditCardModal = true;
    },
    closeCreditCardModal() {
      this.isActiveCreditCardModal = false;
    },
    openAddChargeModal() {
      if (this.isBusinessModule) {
        this.isActiveAddChargeModalCharges = true;
        return;
      }
      this.isActiveAddChargeModal = true;
    },
    closeAddChargeModal() {
      if (this.isBusinessModule) {
        this.isActiveAddChargeModalCharges = false;
        return;
      }
      this.isActiveAddChargeModal = false;
    },
    async deleteWithouPayment(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteWithoutPayment({
            id_transaction: id,
          });
          if (data.status === 200) {
            this.refresh();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    refreshPaymentSchedule() {
      this.keyPaymentSchedule = Math.random();
    },
    async refresh() {
      await this.getCharges(this.accountId);
      await this.getPayments(this.accountId);
      this.$refs.listCharges.refresh();
      this.$refs.listPayments.refresh();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
    },
    ...mapActions({
      getCharges: "ParagonClientDashboard/getCharges",
      getPayments: "ParagonClientDashboard/getPayments",
    }),
  },
  computed: {
    clientObj() {
      return {
        client_name: this.client,
        lead_id: this.leadId,
        program_name: this.program,
      };
    },
    totalCharges() {
      let total = 0;
      this.charges.map((el) => {
        total += parseFloat(el.amount);
      });
      return total;
    },
    totalPayments() {
      let total = 0;
      this.payments.map((el) => {
        if (el.status_id != 3) {
          total += parseFloat(el.amount);
        }
      });
      return total;
    },
    balanceAccount() {
      return this.totalPayments - this.totalCharges;
    },
    ...mapGetters({
      charges: "ParagonClientDashboard/getCharges",
      payments: "ParagonClientDashboard/getPayments",
      leadId: "ParagonClientDashboard/getLeadId",
      client: "ParagonClientDashboard/getClient",
      program: "ParagonClientDashboard/getProgram",
      accountId: "ParagonClientDashboard/getAccountId",
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    programId() {
      return this.$route.matched[0].meta.program;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isBusinessModule() {
      return this.moduleId === 3;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
};
</script>

