<template>
  <b-row class="mx-1 mb-1 mt-3">
    <b-col>
      <h5>TOTAL FEES AND CHARGES</h5>
      <p class="rounded font-medium-1 text-center py-1s" :class="[feeAndCharge>0?'text-primary border-primary':'text-danger border-danger']">
        {{ feeAndCharge | addTwoDecimals }}
      </p>
    </b-col>
    <b-col>
      <h5>TOTAL PAYMENTS</h5>
      <p class="rounded font-medium-1 text-center py-1s" :class="[payments>0?'text-primary border-primary':'text-danger border-danger']">
        {{ payments | addTwoDecimals }}
      </p>
    </b-col>
    <b-col>
      <h5>ACCOUNT BALANCE</h5>
      <p class="rounded font-medium-1 text-center py-1s" :class="[balance>0?'text-primary border-primary':'text-danger border-danger']">
        {{ balance | addTwoDecimals }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    feeAndCharge: {
      type: Number,
      required: true,
    },
    payments: {
      type: Number,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
